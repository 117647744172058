import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Modal, { closeStyle } from "simple-react-modal";
import Header from "../header";
import "./layout.scss";
import styles from "@scss/layout.module.scss";

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <>
            <Helmet>
              <script src="/js/pace.js" />
              <link href="/css/pace-theme.css" rel="stylesheet" />
              <link
                href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                rel="stylesheet"
              />
            </Helmet>
            <div>
              <main>{children}</main>
              <div className={styles.privacy}>
                <Link to={`/privacy-policy`}>Privacy Policy</Link>{" "}
                <Link to={`/terms-and-conditions`}>Terms</Link>
              </div>
            </div>
          </>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
