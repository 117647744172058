import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

function SEO({ description, lang, meta, title }) {
  const pageTitle = title || "Home";
  const siteTitle = "Divina De Campo";
  const metaDescription =
    "Divina De Campo is the stage name of Owen Farrow, a seasoned British drag queen and singer. Known for her high soprano and 4-octave range, Divina has been featured on national television programs including The Voice, All Together Now, and most recently on the first season of RuPaul's Drag Race UK.";

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={pageTitle}
      titleTemplate={`%s | ${siteTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: pageTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: pageTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SEO;
