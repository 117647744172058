import React, { Component, useContext } from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import { get } from "lodash";
import PropTypes from "prop-types"
import CartIcon from "@images/ui-cart.png";
import CartIconActive from "@images/ui-cart-active.png";
import MenuIcon from "@images/ui-menu.png";
import moment from "moment";
import { StoreContext } from "@Context/store-context.js";
import style from "./index.module.scss";

class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      menuOpen: false,
    }
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  componentDidMount(){
    this.handleSpacer();
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
    window.addEventListener("resize", this.handleSpacer);
  }
  toggleMenu(){
    const { menuOpen } = this.state;
    this.setState({menuOpen: !menuOpen});
  }
  closeMenu(){
    this.setState({menuOpen: false});
  }
  handleSpacer(){
    if (!this.headerRef || !this.headerInnerRef || !window) {
      return;
    }
    const height = this.headerInnerRef.offsetHeight;
    this.headerRef.style.minHeight = `${height}px`;
  }
  handleScroll() {
    if (!this.headerRef || !window) {
      return;
    }
    const { isInterior } = this.props;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const headerDistanceFromTop = this.headerRef.offsetTop;
    if( scrollTop > headerDistanceFromTop && !isInterior ){
      this.headerInnerRef.classList.add(style.headerInnerFixed);
    }else {
      this.headerInnerRef.classList.remove(style.headerInnerFixed);
    }
  }
  render(){
    const headerQuery = graphql`
    query headerQuery { 
      prismic {
        events: allEvents(sortBy: date_ASC) {
            edges {
              node {
                title
                date
              }
            }
          }
        
        header(uid: "header", lang: "en-us") {
          title
          links {
            text
            hash
            link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
              ... on PRISMIC_Page {
                title
                _linkType
                _meta {
                  uid
                }
              }
              ... on PRISMIC_Post {
                title
                _linkType
                _meta {
                  uid
                }
              }
            }
          }
        }
      }
    }
  `;
  
    return (
      <StaticQuery
        query={headerQuery}
        render={data => {
          const { menuOpen } = this.state;
          const { isInterior } = this.props;
          const headerLinks = get(data, "prismic.header.links", []);
          const events = get(data, "prismic.events.edges", []);
          const eventsFiltered = events && events.filter((event) => {
            const { node } = event;
              if (
                moment(node.date) > moment()
              ) {
                return true;
              }
          });
          const hideTour = eventsFiltered.length === 0
          const { checkout: { lineItems } } = useContext(StoreContext);
          const cartCount = lineItems.length;
          const cartActive = cartCount > 0
          const cartClasses = cartActive ? style.headerCartButtonActive : "";
          const headerLinkClasses = menuOpen ? `${style.headerLinks} ${style.active}` : `${style.headerLinks}`;
          
          return(<header className={style.header} ref={ele => { this.headerRef = ele; }}>
            <div className={`${style.headerInner}`} ref={ele => { this.headerInnerRef = ele; }}>
              {/* {isInterior && <h2 className={`${style.headerInnerHeader}`}><Link to={`/`}>Home</Link></h2>} */}
              <button onClick={this.toggleMenu} className={`${style.headerHamburger}`}><img src={MenuIcon}/></button>
              <ul className={headerLinkClasses}>
                {headerLinks && headerLinks.map((item, index) => {
                  if( hideTour && item.text.toLowerCase() === "tour" ){ return null; }
                  if( isInterior ){
                    return(<li key={index}><Link onClick={this.closeMenu} to={`/#${item.hash}`}>{item.text}</Link></li>);
                  }else{
                    return(<li key={index}><a onClick={this.closeMenu} href={`#${item.hash}`}>{item.text}</a></li>);
                  }
                })}
              </ul>
              <div className={`${style.headerCartButton} ${cartClasses}`}>
                  <div className={`${style.headerCartButtonInner}`}>
                    {cartActive && <><Link title="Cart" to={`/cart`}><span>{cartCount}</span><img src={CartIconActive}/></Link></>}
                    {!cartActive && <img src={CartIcon}/>}
                  </div>

              </div>
            </div>
          </header>)
        }}/>
    )}
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
