import React from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import slugify from "slugify";
import FontAwesome from "react-fontawesome";
import PlayIcon from "@images/ui-play.png";
import styles from "./youtube-video.module.scss";

const YouTubeVideoFrame = ({ video, tags }) => {
  const {
    node: { title, youtube_id }
  } = video;
  return (
    <div>
        <div className={styles.videosItemThumbWrap}>
          {tags && (
            <div className={styles.videosItemTags}>
              {tags.map((tag, index) => {
                const slug = slugify(tag).toLowerCase();
                return (
                  <Link to={`/tag/${slug}/`} key={index}>
                    <span key={index} title={tag}>
                      {tag}
                    </span>
                  </Link>
                );
              })}
            </div>
          )}
          <div className={styles.videosItemPlay}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.youtube.com/watch/?v=${youtube_id}`}
            >
              <img src={PlayIcon} draggable="false"/>
            </a>
          </div>
          <img
            draggable="false"
            alt={RichText.asText(title)}
            title={RichText.asText(title)}
            src={`https://img.youtube.com/vi/${youtube_id}/maxresdefault.jpg`}
          />
        </div>
        <h4>{RichText.asText(title)}</h4>
      
    </div>
  );
};

export default YouTubeVideoFrame;
